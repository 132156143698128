import React from "react";



function Redirect() {

    const platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
          macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Darwin'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    let url = 'https://play.google.com/store/apps/details?id=com.gnaestudios.digiley';

    if (macosPlatforms.indexOf(platform) !== -1 || iosPlatforms.indexOf(platform) !== -1) {
        url = 'https://apps.apple.com/co/app/digiley/id6446244425';
    }


    window.location.replace(url);
    return <></>;
}

export default Redirect;
