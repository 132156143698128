/*import React from "react";
import { FaSquareXTwitter, FaArtstation, FaInstagram } from "react-icons/fa6";
// Asegúrate de tener un archivo CSS para el estilo

const Art = () => {
  return (
    <div className="video-component">
      <div className="overlay">
        <div className="text-container">
          <p>Tu Texto Aquí</p>
        </div>
        <div className="icons-container">
          <FaSquareXTwitter />
          <FaArtstation />
          <FaInstagram />
        </div>
      </div>
      <video autoPlay loop muted>
        <source src="/art/background.mp4" type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
    </div>
  );
};

export default Art;*/

import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import themeDark from "../themes/themeDark";
import { DarkMode, LightMode, Translate, Palette } from "@mui/icons-material";
import { FaSquareXTwitter, FaArtstation, FaInstagram } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { dark, light, selectTheme } from "../store/reducer/themeReducer";
import { ThemeProvider } from "@mui/material";
import { Typography } from "@mui/material";
import { CustomDivider } from "../components/Divider";
import { Fade } from "@mui/material";
import { Kofi } from "../brands/Kofi";
import { OpenSea } from "../brands/OpenSea";
import { Steam } from "../brands/Steam";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Art() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState("es");
  const themeUsed = useSelector(selectTheme);
  const dispatch = useDispatch();

  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value;
    setLanguage(e.target.value);
    i18n.changeLanguage(languageValue);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={themeUsed}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={open ? "App-header-open" : "App-header"}
        sx={{ left: 0, width: { xl: 65, lg: 65, sm: 65, xs: 56 } }}
        open={open}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key={t("altLogo")} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Link
                  color="inherit"
                  href="/"
                  underline="none"
                  aria-label={t("home")}
                >
                  <img
                    src={"../logo.svg"}
                    alt={t("altLogo")}
                    style={{ width: 24, height: 24 }}
                    loading="lazy"
                  />
                </Link>
              </ListItemIcon>
              <ListItemText
                primary={t("gnaestudios")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key={t("art")} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Link
                  color="inherit"
                  href="/art"
                  underline="none"
                  aria-label={t("art")}
                >
                  <Palette />
                </Link>
              </ListItemIcon>
              <ListItemText primary={t("art")} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={t("language")}
            disablePadding
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (!open) {
                    let keys = Object.keys(i18n.options.resources);
                    let i = 0;
                    let goOn = true;
                    for (; i < keys.length && goOn; i++) {
                      let lang = keys[i];
                      goOn = lang !== language;
                    }
                    let nextIndex = i % keys.length;
                    setLanguage(keys[nextIndex]);
                    i18n.changeLanguage(keys[nextIndex]);
                  }
                }}
              >
                <Translate />
              </ListItemIcon>
              {open ? (
                <FormControl>
                  <InputLabel id="language_input">{t("language")}</InputLabel>
                  <Select
                    labelId="language_input_label"
                    id="language_input_label_select"
                    value={language}
                    onChange={changeLanguageHandler}
                    autoWidth
                    label={t("language")}
                  >
                    <MenuItem value={"es"}>Español</MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                  </Select>
                </FormControl>
              ) : null}
            </ListItemButton>
          </ListItem>
          <ListItem key={t("theme")} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                if (themeUsed === themeDark) {
                  dispatch(light());
                } else {
                  dispatch(dark());
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {themeUsed === themeDark ? <LightMode /> : <DarkMode />}
              </ListItemIcon>
              <ListItemText
                primary={t("theme")}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <div className="video-component">
        <div className="overlay">
          <div className="text-container">
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 10 }}
            >
              <Stack
                direction="column"
                sx={{
                  ml: { xl: "5vw", lg: "13vw", sm: "15vw", xs: "17vw" },
                  mr: {
                    xl: "250vw",
                    lg: "250vw",
                    sm: "175vw",
                    xs: "175vw",
                  },
                }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Fade
                  direction="right"
                  in={true}
                  mountOnEnter
                  unmountOnExit
                  timeout={1550}
                >
                  <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    style={{
                      paddingTop: "20vh",
                      color: "black",
                      marginBottom: "2vh",
                    }}
                  >
                    {t("art_title")}
                  </Typography>
                </Fade>
                <CustomDivider
                  style={{
                    marginTop: "0",
                    marginBottom: "0",
                    width: 100,
                    fill: themeUsed.palette.primary.main,
                  }}
                />
                <Typography
                  style={{
                    color: "black",
                  }}
                  align="justify"
                >
                  {t("art_p1")}
                </Typography>
                <Typography
                  style={{
                    color: "black",
                  }}
                  align="justify"
                >
                  {t("art_p2")}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              style={{ paddingTop: "15vh" }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 10 }}
            >
              <Stack
                direction="column"
                sx={{
                  ml: { xl: "5vw", lg: "13vw", sm: "15vw", xs: "17vw" },
                  mr: {
                    xl: "77vw",
                    lg: "175vw",
                    sm: "175vw",
                    xs: "175vw",
                  },
                }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Typography
                  style={{
                    color: "black",
                  }}
                  variant="h3"
                  align="left"
                  gutterBottom
                >
                  {t("art_subtitle_1")}
                </Typography>
                <CustomDivider
                  style={{
                    marginTop: "0",
                    marginBottom: "1.5vh",
                    width: 100,
                    fill: themeUsed.palette.primary.main,
                  }}
                />
                <Link
                  color="inherit"
                  href="https://ko-fi.com/gnaestudios"
                  underline="none"
                  aria-label="KoFi Gnaestudios"
                >
                  <Stack direction="row">
                    <Kofi
                      style={{
                        height: 42,
                        width: 42,
                      }}
                    />
                    <Typography
                      style={{
                        marginTop: "1vh",
                        color: "black",
                        marginLeft: "1vw",
                      }}
                      align="justify"
                    >
                      {t("art_p3")}
                    </Typography>
                  </Stack>
                </Link>
                <Link
                  color="inherit"
                  href="https://steamcommunity.com/profiles/76561197980394780/myworkshopfiles/?appid=431960"
                  underline="none"
                  aria-label="Steam Workshop Gnaestudios"
                  style={{
                    marginTop: "3vh",
                  }}
                >
                  <Stack direction="row">
                    <Steam
                      style={{
                        height: 42,
                        width: 42,
                      }}
                    />
                    <Typography
                      style={{
                        marginTop: "1vh",
                        color: "black",
                        marginLeft: "1vw",
                      }}
                      align="justify"
                    >
                      {t("art_p4")}
                    </Typography>
                  </Stack>
                </Link>
                <Link
                  color="inherit"
                  href="https://opensea.io/GnAestudios"
                  underline="none"
                  aria-label="OpenSea Gnaestudios"
                  style={{
                    marginTop: "3vh",
                  }}
                >
                  <Stack direction="row">
                    <OpenSea
                      style={{
                        height: 42,
                        width: 42,
                      }}
                    />
                    <Typography
                      style={{
                        marginTop: "1vh",
                        color: "black",
                        marginLeft: "1vw",
                      }}
                      align="justify"
                    >
                      {t("art_p5")}
                    </Typography>
                  </Stack>
                </Link>
              </Stack>
            </Stack>
            <Stack
              style={{ paddingTop: "15vh" }}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 10 }}
            >
              <Stack
                direction="column"
                sx={{
                  ml: { xl: "5vw", lg: "5vw", sm: "10vw", xs: "15vw" },
                  mr: {
                    xl: "77vw",
                    lg: "175vw",
                    sm: "175vw",
                    xs: "175vw",
                  },
                }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <Typography
                  style={{
                    color: "black",
                  }}
                  variant="h3"
                  align="left"
                  gutterBottom
                >
                  {t("art_subtitle_2")}
                </Typography>
                <CustomDivider
                  style={{
                    marginTop: "0",
                    marginBottom: "1.5vh",
                    width: 100,
                    fill: themeUsed.palette.primary.main,
                  }}
                />
                <Link
                  color="inherit"
                  href="https://twitter.com/GnAestudios"
                  underline="none"
                  aria-label="Twitter (X) Gnaestudios"
                >
                  <Stack direction="row">
                    <FaSquareXTwitter
                      style={{
                        color: "black",
                      }}
                      size={42}
                    />
                    <Typography
                      style={{
                        marginTop: "0.5vh",
                        color: "black",
                        marginLeft: "0.5vw",
                      }}
                    >
                      @GnaEstudios
                    </Typography>
                  </Stack>
                </Link>
                <Link
                  color="inherit"
                  href="https://www.artstation.com/gnaestudios9"
                  underline="none"
                  aria-label="ArtStation Gnaestudios"
                >
                  <Stack direction="row">
                    <FaArtstation
                      style={{
                        color: "black",
                      }}
                      size={42}
                    />
                    <Typography
                      style={{
                        marginTop: "0.5vh",
                        color: "black",
                        marginLeft: "0.5vw",
                      }}
                    >
                      @GnaEstudios
                    </Typography>
                  </Stack>
                </Link>
                <Link
                  color="inherit"
                  href="https://www.instagram.com/gnaestudios"
                  underline="none"
                  aria-label="Instagram Gnaestudios"
                >
                  <Stack direction="row">
                    <FaInstagram
                      style={{
                        color: "black",
                      }}
                      size={42}
                    />
                    <Typography
                      style={{
                        marginTop: "0.5vh",
                        color: "black",
                        marginLeft: "0.5vw",
                      }}
                    >
                      @GnaEstudios
                    </Typography>
                  </Stack>
                </Link>
              </Stack>
            </Stack>
          </div>
        </div>
          <Box>
              <video
                  controls=""
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="hidden-xs"
                  width={2000}
              >
                  <source src="/art/background2.mp4" type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
              </video>
              <video
                  controls=""
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="show-only-xs"
                  width={1000}
              >
                  <source src="/art/background.mp4" type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
              </video>
          </Box>
      </div>
    </ThemeProvider>
  );
}
