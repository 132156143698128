export const ProductsIcon = (props) =>{
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            fillRule="evenodd"
            clipRule="evenodd"
            imageRendering="optimizeQuality"
            shapeRendering="geometricPrecision"
            textRendering="geometricPrecision"
            viewBox="0 0 203.2 179.04"
            {...props}
        >
            <g fill="currentColor">
                <path d="M202.78 109.28c-1.18-2.94-4.52-4.42-7.5-3.19l-71.8 28.36c-4.96-11.17-16.13-18.96-29.02-18.96-.97 0-1.84.05-2.73.19L50.64 3.79C49.54.89 46.46-.63 43.52.26L4.13 12.16C1 13.12-.69 16.32.27 19.4a5.678 5.678 0 0 0 7.17 3.86l34.17-10.33 38.93 105.83c-10.54 5.15-17.85 15.95-17.85 28.48 0 17.52 14.25 31.8 31.79 31.8 17.52 0 31.75-14.26 31.75-31.8 0-.52-.05-.96-.12-1.39l73.42-29.02c2.99-1.13 4.42-4.56 3.27-7.52l-.02-.03zM94.46 164.64c-9.67 0-17.43-7.83-17.43-17.42 0-9.6 7.83-17.38 17.43-17.38 9.55 0 17.38 7.78 17.38 17.38 0 9.59-7.83 17.42-17.38 17.42z" />
                <path d="M100.29 147.24c0-3.2-2.66-5.83-5.83-5.83-3.25 0-5.88 2.65-5.88 5.83 0 3.24 2.66 5.88 5.88 5.88 3.2 0 5.83-2.66 5.83-5.88zM171.34 101.05l-16.07-42.8-6.28 2.31v.07l-36.52 13.66 16.09 42.8 42.78-16.06v.02zm-62.96-37.6 31.04-11.64-11.64-31.09-31.11 11.69 11.71 31.04z" />
            </g>
        </svg>
    )
}